<template>
  <div class="reset-password">
    <div class="reset-password-left">
      <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/'}`">
        <img alt="" src="../assets/image/logo.svg" class="logo" />
      </router-link>
      <img
        alt=""
        src="../assets/image/reset_password_bg.svg"
        class="reset-password-bg"
      />
    </div>
    <div class="reset-password-right">
      <!--reset password-->
      <div class="reset-password-box" v-show="showResetBox">
        <div class="reset-password-title">
          {{ $t("reset_password_title") }}
        </div>
        <div class="reset-password-tip">
          {{ $t("reset_password_subtitle") }}
        </div>
        <div class="input-box input-password-space">
          <el-input
            :key="newPasswordType"
            ref="newPassword"
            v-model="newPassword"
            class="form-pwd__input"
            :type="newPasswordType"
            :placeholder="$t('reset_password_new_password')"
            name="newPassword"
            tabindex="2"
            auto-complete="on"
          >
            <template #suffix>
              <span
                class="show-or-hide-password"
                @click="showOrHidePassword('newPasswordType', 'newPassword')"
              >
                <img
                  class="show-or-hide-pwd-icon"
                  :src="
                    newPasswordType === 'password'
                      ? require('../assets/icons/form_hide_pwd.svg')
                      : require('../assets/icons/form_show_pwd.svg')
                  "
                />
              </span>
            </template>
          </el-input>
        </div>
        <div class="input-box confirm-password-space">
          <el-input
            :key="confirmPasswordType"
            ref="confirmPassword"
            v-model="confirmPassword"
            class="form-pwd__input"
            :type="confirmPasswordType"
            :placeholder="$t('reset_password_confirm_password')"
            name="confirmPassword"
            tabindex="3"
            auto-complete="on"
          >
            <template #suffix>
              <span
                class="show-or-hide-password"
                @click="
                  showOrHidePassword('confirmPasswordType', 'confirmPassword')
                "
              >
                <img
                  class="show-or-hide-pwd-icon"
                  :src="
                    confirmPasswordType === 'password'
                      ? require('../assets/icons/form_hide_pwd.svg')
                      : require('../assets/icons/form_show_pwd.svg')
                  "
                />
              </span>
            </template>
          </el-input>
        </div>
        <button
          type="button"
          class="
            reset-password-btn
            btn-size-format-2 btn-radius-format-1 btn-color-1 btn-cursor
          "
          @click="checkAndSavePassword"
        >
          <span>{{ $t("reset_password_save") }}</span>
        </button>
        <div class="sign-in-btn btn-cursor">
          <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/login'}`"
            >{{ $t("forget_password_sign_in") }}
          </router-link>
        </div>
      </div>
      <!--reset password success-->
      <div class="reset-password-success-box" v-show="showResetSuccess">
        <div class="reset-password-success-title">
          {{ $t("reset_password_password_reset_successful") }}
        </div>
        <div class="reset-password-success-tip">
          {{ $t("reset-password-success") }}
        </div>
        <div class="reset-password-success-image">
          <img alt="" src="../assets/image/reset_password_reset_success.svg" />
        </div>
        <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/login'}`">
          <button
            type="button"
            class="
              back-login-btn
              btn-size-format-2 btn-radius-format-1 btn-color-1 btn-cursor
            "
          >
            <span>{{ $t("reset-password-back_to_login") }}</span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      newPasswordType: "password",
      confirmPasswordType: "password",
      newPassword: null,
      confirmPassword: null,
      showResetBox: true,
      showResetSuccess: false,
    };
  },
  methods: {
    showOrHidePassword(passwordTypeName, passwordRefName) {
      if (this[passwordTypeName] === "password") {
        this[passwordTypeName] = "";
      } else {
        this[passwordTypeName] = "password";
      }
      this.$nextTick(() => {
        this.$refs[passwordRefName].focus();
      });
    },
    checkAndSavePassword() {
      if (this.checkPassword()) {
        this.savePassword();
      }
    },
    checkPassword() {
      if (!this.newPassword || !this.confirmPassword) return false;
      if (this.newPassword.length < 8 || this.newPassword.length > 16) {
        this.$message.error("password length between 8 and 16");
        return false;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.$message.error("Entered passwords differ!");
        return false;
      }
      return true;
    },
    savePassword() {
      this.$axios
        .patch("/auth/reset-password", {
          id: this.$route.query.id,
          email: this.$route.query.email,
          newPassword: this.newPassword,
          newPasswordConfirm: this.confirmPassword,
        })
        .then(() => {
          this.showResetBox = false;
          this.showResetSuccess = true;
        });
    },
  },
  created() {},
  mounted() {
  },
};
</script>

<style scoped>
.reset-password {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
}

.reset-password-left {
  width: 100%;
  max-width: 1167px;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
}

@media screen and (max-width: 1024px) {
  .reset-password-left {
    display: none;
  }
}

.logo {
  margin-top: 43px;
  margin-left: 114px;
}

.reset-password-bg {
  margin-top: 103px;
  max-width: 80%;
  align-self: center;
}

.reset-password-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
}

.reset-password-box {
  padding: 175px 16px;
}

.input-password-space {
  margin-top: 40px;
}

.confirm-password-space {
  margin-top: 24px;
}

.reset-password-title {
  font-size: 40px;
  font-weight: 400;
  line-height: 49px;
  color: #161823;
}

.reset-password-tip {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #161823;
  margin-top: 10px;
}

.input-box {
  height: 60px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
}

.reset-password-btn {
  width: 100%;
  margin-top: 24px;
}

.show-or-hide-password {
  margin-right: 20px;
  line-height: 60px;
}

.show-or-hide-pwd-icon {
  width: 20px;
}

.sign-in-btn {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(20, 25, 35, 0.6);
  opacity: 0.8;
  text-align: right;
}

.reset-password-success-box {
  max-width: 359px;
  margin: 175px 16px 0;
}

.reset-password-success-title {
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  color: #161823;
}

.reset-password-success-tip {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #161823;
  margin-top: 10px;
}

.reset-password-success-image {
  margin-top: 9px;
  text-align: center;
}

.reset-password-success-image img {
  max-width: 100%;
  height: auto;
}

.back-login-btn {
  width: 100%;
  margin-top: 2px;
}
</style>
